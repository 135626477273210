<template> 
    <!-- WP -->
    <template v-if="nodeType === 'WP'">
        <i class="bi bi-box treeIcon"></i> 
    </template>
    <!-- WBS -->
    <template v-else-if="nodeType === 'WBS'">
        <i class="bi bi-folder treeIcon"></i> 
    </template>
    <!-- Project -->
    <template v-else-if="nodeType === 'Project'">
        <i class="bi bi-briefcase treeIcon"></i>
    </template>

    <!-- Contract -->
    <template v-else-if="nodeType === 'Contract'">
        <i class="bi bi-file-earmark-text treeIcon"></i> 
    </template>

    <!-- Procuement -->
    <template v-else-if="nodeType === 'Procurement'">
        <i class="bi bi-boxes treeIcon"></i> 
    </template>
    <!-- Bid -->
    <template v-else-if="nodeType === 'Bid'">
        <i class="bi bi-card-list treeIcon"></i> 
    </template>
    <!-- Bidder -->
    <template v-else-if="nodeType === 'Bidder'">
        <i class="bi bi-person-video treeIcon"></i>
    </template>

    <!-- OrgUnit -->
    <template v-else-if="nodeType === 'OrgUnit'">
        <i class="bi bi-diagram-3 treeIcon"></i> 
    </template>
</template>
<script setup>

const props = defineProps({
    nodeType: {
        type: String
    }
})
</script>
<style scoped>
.treeIcon{
    margin-right: 0.5rem;
    cursor: pointer;
}
</style>
